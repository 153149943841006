import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-29f255bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cardEngine" }
const _hoisted_2 = { class: "btnGroup" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  id: "my-page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_CardForm = _resolveComponent("CardForm")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_EditTableItem = _resolveComponent("EditTableItem")!
  const _component_EditTable = _resolveComponent("EditTable")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_backtop = _resolveComponent("el-backtop")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_header, {
          style: _normalizeStyle({height: _ctx.buttons.length!=0?'40px':'0px'})
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (btn) => {
                return (_openBlock(), _createBlock(_component_el_button, {
                  key: btn.id,
                  type: btn.type,
                  class: _normalizeClass(btn.icon),
                  plain: "",
                  onClick: ($event: any) => (_ctx.clickHandler(btn.clickEvent))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(btn.title), 1)
                  ]),
                  _: 2
                }, 1032, ["type", "class", "onClick"]))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["style"]),
        _createVNode(_component_el_main, null, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              style: _normalizeStyle({'max-height':_ctx.mainMaxHeight+'px',overflow: 'auto'}),
              class: "backTarget"
            }, [
              _createVNode(_component_el_card, null, {
                default: _withCtx(() => [
                  (_ctx.otherParams.compParams.isEnginePage)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_el_form, {
                          ref: "formRef",
                          model: _ctx.form,
                          rules: _ctx.otherParams.compParams.rules,
                          "label-width": "100px"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_CardForm, {
                              ownerInst: this,
                              formItems: _ctx.otherParams.compParams.formItems,
                              form: _ctx.form,
                              disabled: _ctx.otherParams.compParams.disabled,
                              refMap: _ctx.refMap,
                              colNum: _ctx.otherParams.compParams.colNum
                            }, null, 8, ["formItems", "form", "disabled", "refMap", "colNum"])
                          ]),
                          _: 1
                        }, 8, ["model", "rules"]),
                        (_ctx.otherParams.compParams.oneDetail)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.otherParams.compParams.detailInfos, (item, key) => {
                              return (_openBlock(), _createBlock(_component_EditTable, _mergeProps({
                                "parent-inst": this,
                                ref_for: true,
                                ref: el=>_ctx.refMap.set(key,el)
                              }, _ctx.detailParam({detailType:key,title:item.title}), { ref_for: true }, _ctx.otherParams.compParams.events), {
                                tbCols: _withCtx(() => [
                                  _createVNode(_component_EditTableItem, {
                                    ownerInst: this,
                                    editTableItems: item.columns,
                                    refMap: _ctx.refMap
                                  }, null, 8, ["editTableItems", "refMap"])
                                ]),
                                _: 2
                              }, 1040))
                            }), 256))
                          : (_openBlock(), _createBlock(_component_el_tabs, {
                              key: 1,
                              type: "border-card"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherParams.compParams.detailInfos, (item, key) => {
                                  return (_openBlock(), _createBlock(_component_el_tab_pane, {
                                    label: item.title
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_EditTable, _mergeProps({
                                        ref_for: true,
                                        ref: el=>_ctx.refMap.set(key,el)
                                      }, _ctx.detailParam({detailType:key,title:item.title}), {
                                        "parent-inst": this,
                                        ref_for: true
                                      }, _ctx.otherParams.compParams.events), {
                                        tbCols: _withCtx(() => [
                                          _createVNode(_component_EditTableItem, {
                                            ownerInst: this,
                                            editTableItems: item.columns,
                                            refMap: _ctx.refMap
                                          }, null, 8, ["editTableItems", "refMap"])
                                        ]),
                                        _: 2
                                      }, 1040)
                                    ]),
                                    _: 2
                                  }, 1032, ["label"]))
                                }), 256))
                              ]),
                              _: 1
                            }))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modelComp), { ref: "modelCompRef" }, null, 512))
                      ]))
                ]),
                _: 1
              })
            ], 4),
            (_ctx.otherParams.compParams.showBackTarget)
              ? (_openBlock(), _createBlock(_component_el_backtop, {
                  key: 0,
                  target: ".backTarget",
                  "visibility-height": 20
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ])), [
    [_directive_loading, _ctx.loading]
  ])
}